import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const halloweenPrompts = [
  "A cute ghost",
  "A smiling jack-o'-lantern",
  "A black cat with big sparkly eyes",
  "A tiny bat",
  "A candy corn",
  "A little vampire",
  "A cute werewolf pup",
  "A zombie",
  "A smiling skeleton head",
  "A witches cauldron",
  "A cute spider",
  "A haunted house",
  "A smiling moon",
  "A dressed ghost",
  "A candy apple",
  "A trick-or-treat bag",
  "A tiny Frankenstein monster",
  "A tiny goblin",
  "A tiny cat",
];

const negativePrompt = "no text, no numbers, no background, no border, racist, xenophobic, antisemitic, islamophobic, bigoted";

export const testFetch = await fetch("https://catfact.ninja/fact").then((response) => response.json()).then((data) => {
  return data;
});

export async function renderHalloweenEmotes(count = 1) {
  const randomPrompt = halloweenPrompts[Math.floor(Math.random() * halloweenPrompts.length)];
  const basePrompt = `A TOK emoji of ${randomPrompt.toLowerCase()}`;

  const data = {
    providers: "replicate/classic",
    text: basePrompt.toLowerCase(),
    resolution: "512x512",
    num_images: count,
    negative_prompt: negativePrompt,
  };

  const generate = await makeFetch("https://api.edenai.run/v2/image/generation", {
    method: "POST",
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_EDENAI_API_KEY}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  try {
    return await { images: generate['replicate/classic'].items, prompt: basePrompt };
  } catch (error) {
    console.log(error)
  }
}

export async function renderEmotes(prompt, refinementOption, selectedColor, count = 1) {
  const basePrompt = `A TOK emoji of ${prompt}, in ${refinementOption} style.`;

  const data = {
    providers: "replicate/classic",
    text: basePrompt,
    resolution: "512x512",
    num_images: count,
    negative_prompt: negativePrompt,
  };


  const generate = await makeFetch("https://api.edenai.run/v2/image/generation", {
    method: "POST",
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_EDENAI_API_KEY}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  try {
    return await generate['replicate/classic'].items;
  } catch (error) {
    console.log(error)
  }
}

export async function removeBackground(imageUrl) {
  // Convert image URL to File object
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  const file = new File([blob], "image.png", { type: "image/png" });

  const formData = new FormData();
  formData.append('file', file);
  formData.append('providers', 'sentisight');

  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${process.env.REACT_APP_EDENAI_API_KEY}`
    },
    body: formData
  };

  const removeBackground = await makeFetch("https://api.edenai.run/v2/image/background_removal", options);

  try {
    return await removeBackground.sentisight.image_resource_url;
  } catch (error) {
    console.log(error)
  }
}

async function makeFetch(url, options) {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    return await response.json().then((data) => {
      console.log(data);
      return data;
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const uploadImage = async (imageFile) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    console.error('User not authenticated');
    return;
  }

  const storage = getStorage();
  const imageName = `${Date.now()}_${imageFile.name}`; // Unique file name
  const storageRef = ref(storage, `images/${user.uid}/${imageName}`);

  try {
    const snapshot = await uploadBytes(storageRef, imageFile);
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log('Image uploaded:', downloadURL);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading image:', error);
  }
};