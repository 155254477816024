import React, { useState, useRef } from 'react';
import { renderEmotes, renderHalloweenEmotes, renderPremiumEmotes, uploadImage } from '../server';
import { doc, updateDoc, arrayUnion, getFirestore, getDoc, runTransaction } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import toast from 'react-hot-toast';
import "../styles/Generator.css";
import Modal from './Modal'; // You'll need to create this component
import { FaPaintBrush, FaCamera, FaGamepad, FaPalette, FaPencilAlt, FaRegLightbulb, FaCube, FaRobot, FaClock, FaFire } from 'react-icons/fa';

export default function Generator({ refreshUserData, userEmotes }) {
  const scrollRef = useRef(null);
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [refinementOption, setRefinementOption] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [generatedEmotes, setGeneratedEmotes] = useState([]);
  const [selectedEmote, setSelectedEmote] = useState(null);
  const [showAllEmotes, setShowAllEmotes] = useState(false);
  const [selectedColor, setSelectedColor] = useState('#000000');
  const [generationData, setGenerationData] = useState(null);
  const [generatedEmoteCount, setGeneratedEmoteCount] = useState(1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGenerationData({ prompt, refinementOption, primaryColor: selectedColor });
    setShowModal(true);
  };

  const confirmHalloweenGeneration = async () => {
    setShowModal(false);
    setLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('User not authenticated');
      setLoading(false);
      return;
    }

    const db = getFirestore();
    const userDoc = doc(db, 'users', user.uid);

    try {
      // Get the current user's document
      const userSnapshot = await getDoc(userDoc);
      const userData = userSnapshot.data();
      const currentCredits = userData.credits || 0;

      if (currentCredits < 100 * generatedEmoteCount) {
        toast.error("Not enough credits");
        throw new Error('Not enough credits');
      }

      // Generate emotes with the new primaryColor parameter and count
      const results = await renderHalloweenEmotes(generatedEmoteCount);

      console.log("results", results);

      // Deduct credits
      await updateDoc(userDoc, { credits: currentCredits - (100 * generatedEmoteCount) });
      toast.success(`${generatedEmoteCount} emote(s) generated successfully, credits deducted`);

      console.log(results.images[0])
      console.log(results.prompt)

      // Upload images and get URLs
      const uploadPromises = results.images.map(async (result) => {
        const response = await fetch(result.image_resource_url);
        const blob = await response.blob();
        const file = new File([blob], `emote_${Date.now()}.png`, { type: 'image/png' });
        return uploadImage(file);
      });

      const uploadedUrls = await Promise.all(uploadPromises);

      // Prepare new emotes array with uploaded URLs
      const newEmotes = uploadedUrls.map(url => ({
        prompt: results.prompt,
        imageUrl: url,
        createdAt: new Date(),
        primaryColor: generationData?.primaryColor || "#000000"
      }));

      // Update user document with new emotes
      await updateDoc(userDoc, {
        emotes: arrayUnion(...newEmotes)
      });

      // Update state with new emotes
      setGeneratedEmotes(prevEmotes => [...prevEmotes, ...newEmotes]);

      // After successful generation and database update
      await refreshUserData();
      setPrompt("");
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      console.log(`${generatedEmoteCount} emote(s) generated, uploaded, and saved, credits deducted`);
    } catch (error) {
      if (error.message === 'Not enough credits') {
        toast.error('Not enough credits to generate emote');
      } else if (error.message === "Server returned an error") {
        toast.error('An error occurred while generating the emote. Please try again.');
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
      console.error('Error generating image:', error);
    } finally {
      setLoading(false);
      setGenerationData(null); // Reset generation data
    }
  };

  const confirmGeneration = async () => {
    setShowModal(false);
    setLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('User not authenticated');
      setLoading(false);
      return;
    }

    const db = getFirestore();
    const userDoc = doc(db, 'users', user.uid);

    try {
      // Get the current user's document
      const userSnapshot = await getDoc(userDoc);
      const userData = userSnapshot.data();
      const currentCredits = userData.credits || 0;

      if (currentCredits < 100 * generatedEmoteCount) {
        toast.error("Not enough credits");
        throw new Error('Not enough credits');
      }

      // Generate emotes with the new primaryColor parameter and count
      const results = await renderEmotes(generationData.prompt, generationData.refinementOption, generationData.primaryColor, generatedEmoteCount);

      console.log("results", results);

      // Deduct credits
      await updateDoc(userDoc, { credits: currentCredits - (100 * generatedEmoteCount) });
      toast.success(`${generatedEmoteCount} emote(s) generated successfully, credits deducted`);

      // Upload images and get URLs
      const uploadPromises = results.map(async (result) => {
        const response = await fetch(result.image_resource_url);
        const blob = await response.blob();
        const file = new File([blob], `emote_${Date.now()}.png`, { type: 'image/png' });
        return uploadImage(file);
      });

      const uploadedUrls = await Promise.all(uploadPromises);

      // Prepare new emotes array with uploaded URLs
      const newEmotes = uploadedUrls.map(url => ({
        prompt: generationData.prompt,
        imageUrl: url,
        createdAt: new Date(),
        primaryColor: generationData.primaryColor
      }));

      // Update user document with new emotes
      await updateDoc(userDoc, {
        emotes: arrayUnion(...newEmotes)
      });

      // Update state with new emotes
      setGeneratedEmotes(prevEmotes => [...prevEmotes, ...newEmotes]);

      // After successful generation and database update
      await refreshUserData();
      setPrompt("");
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      console.log(`${generatedEmoteCount} emote(s) generated, uploaded, and saved, credits deducted`);
    } catch (error) {
      if (error.message === 'Not enough credits') {
        toast.error('Not enough credits to generate emote');
      } else if (error.message === "Server returned an error") {
        toast.error('An error occurred while generating the emote. Please try again.');
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
      console.error('Error generating image:', error);
    } finally {
      setLoading(false);
      setGenerationData(null); // Reset generation data
    }
  };

  const styleOptions = [
    { label: 'Realistic', value: 'realistic', icon: FaCamera },
    { label: 'Cartoon', value: 'cartoon', icon: FaPaintBrush },
    { label: 'Anime', value: 'anime', icon: FaPalette },
    { label: 'Pixel Art', value: 'pixel_art', icon: FaGamepad },
    { label: 'Watercolor', value: 'watercolor', icon: FaPaintBrush },
    { label: 'Sketch', value: 'sketch', icon: FaPencilAlt },
    { label: 'Pop Art', value: 'pop_art', icon: FaRegLightbulb },
    { label: 'Minimalist', value: 'minimalist', icon: FaCube },
    { label: 'Retro', value: 'retro', icon: FaClock },
    { label: 'Cyberpunk', value: 'cyberpunk', icon: FaRobot },
    { label: 'Halloween', value: 'halloween', icon: FaFire },
  ];

  const handleEmoteClick = (emote) => {
    setSelectedEmote(emote);
  };

  const handleCloseModal = () => {
    setSelectedEmote(null);
  };

  return (
    <div className="grid justify-center align-center gap-4 content-center overflow-y-auto">
      <h3 className="typewriter">
        <span>Create</span>
      </h3>

      <div>
        <p className="text-base text-gray-500">Generate an emote based on your prompt.</p>
      </div>

      <button
        onClick={() => confirmHalloweenGeneration()}
        className="group mt-4 mb-4 relative overflow-hidden px-8 py-3 bg-orange-400 text-white text-lg font-semibold rounded-md shadow-md hover:bg-orange-500 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-orange-300 focus:ring-opacity-50 mx-auto block"
      >
        <span className="relative z-10 font-extrabold">Random Spooky Emote</span>
        <div className="absolute inset-0 h-full w-full scale-0 rounded-full bg-white opacity-10 transition-all duration-300 group-hover:scale-100"></div>
      </button>

      <style jsx>{`
        @keyframes floatAnimation {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }

        .float-animation {
          animation: floatAnimation 3s ease-in-out infinite;
        }
      `}</style>

      <form onSubmit={handleSubmit} className="mb-4 flex flex-col items-center justify-center bg-zinc-700 p-2 rounded-md w-full max-w-[75%] min-w-[70vw] mx-auto">
        <h2 className="text-2xl font-extrabold text-white mb-4 text-center">Generate an Emote</h2>
        <div className="flex flex-col sm:flex-row items-center justify-between gap-2 w-full">
          <input
            type="text"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="a silly cat on the moon"
            className="rounded-md text-base p-2 bg-zinc-800 w-full mb-2 sm:mb-0"
          />
          <input
            type="number"
            id="emoteCount"
            value={generatedEmoteCount}
            onChange={(e) => setGeneratedEmoteCount(Math.max(1, parseInt(e.target.value)))}
            min="1"
            max="5"
            className="rounded-md text-base p-2 bg-zinc-800 w-50"
          />
          <button
            type="submit"
            className="text-base px-5 bg-gradient-to-r from-zinc-800 to-zinc-900 text-white py-2 rounded-md shadow-lg hover:shadow-xl transition duration-300 ease-in-out transform hover:scale-105 w-full sm:w-auto"
            disabled={loading === true || prompt === '' || refinementOption === ''}
          >
            Generate
          </button>
        </div>
      </form>

      <div className="mr-4 ml-4 bg-zinc-700 p-4 rounded-lg">
        <h3 className="text-lg font-semibold mb-4 text-white">Select Style</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
          {styleOptions.map((option) => (
            <button
              key={option.value}
              type="button"
              onClick={() => setRefinementOption(option.value)}
              className={`flex flex-col items-center justify-center p-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 ${refinementOption === option.value
                ? 'bg-blue-600 text-white'
                : 'bg-zinc-800 text-gray-300 hover:bg-zinc-600'
                }`}
            >
              <option.icon className="text-2xl mb-2" />
              <span className="text-sm text-center">{option.label}</span>
            </button>
          ))}
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-zinc-800 rounded-lg p-6 max-w-sm w-full relative">
            <h3 className="text-lg font-semibold mb-4 text-white">Confirm Generation</h3>
            <p className="mb-4 text-lg">Are you sure you want to spend {100 * generatedEmoteCount} credits to generate {generatedEmoteCount} image(s)?</p>
            <p className="mb-4 text-sm bg-zinc-700 p-2 rounded-md">Prompt: {generationData?.prompt}</p>
            <p className="mb-4 text-sm bg-zinc-700 p-2 rounded-md">Style: {generationData?.refinementOption}</p>
            <div className="mb-4 flex items-center justify-center gap-2 bg-zinc-700 p-2 rounded-md">
              <span className="text-sm mr-2">Primary Color:</span>
              <div
                className="w-6 h-6 rounded-full"
                style={{ backgroundColor: generationData?.primaryColor }}
              ></div>
            </div>
            <div className="flex justify-center gap-5">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 text-sm p-3"
              >
                Cancel
              </button>
              <button
                onClick={confirmGeneration}
                className="px-4 py-2 bg-zinc-600 text-white rounded hover:bg-zinc-700 text-sm"
              >
                Confirm ({100 * generatedEmoteCount} Credits)
              </button>
            </div>
          </div>
        </div>
      )}

      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-zinc-800 rounded-lg p-6 max-w-sm w-full text-center">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500 mx-auto mb-4"></div>
            <p className="text-white text-lg">Generating your emote(s)...</p>
          </div>
        </div>
      )}

      {generatedEmotes.length > 0 && (
        <>
          <div className="mt-4 p-4">
            <h2 className="text-center">Your generated emotes:</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-4">
              {generatedEmotes.map((emote, index) => (
                <div key={index} className="cursor-pointer grid grid-cols-1 max-w-[300px] text-pretty align-center justify-center bg-zinc-800 p-5 rounded-md hover:bg-zinc-700" onClick={() => handleEmoteClick(emote)}>
                  <img
                    src={emote.imageUrl}
                    alt={emote.prompt}
                    className="w-full h-auto rounded-lg"
                  />
                  <p className="text-center text-sm text-gray-500 mt-1 truncate p-2">{emote.prompt}</p>
                </div>
              ))}
            </div>
          </div>
          <div style={{ padding: ".25rem" }} ref={scrollRef}></div>
        </>
      )}

      {selectedEmote && (
        <Modal
          images={generatedEmotes}
          initialImageUrl={selectedEmote.imageUrl}
          prompt={selectedEmote.prompt}
          onClose={handleCloseModal}
        />
      )}

      <div style={{ padding: "1rem" }}></div>
    </div>
  );
}
