import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Navbar from './components/Navbar';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signInWithRedirect, getRedirectResult } from 'firebase/auth'; // Add signInWithRedirect import
import { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'; // Import Firestore functions
import { Toaster } from 'react-hot-toast';
import { Helmet } from "react-helmet";


// Components
import Pricing from './components/Pricing';
import Profile from './components/Profile';
import GetStarted from './components/GetStarted';
import './styles/scrollbar.css';
import Generator from './components/Generator';
import Community from './components/Community';
import ImageEditor from './components/ImageEditor';
import Test from './components/Test';
import ConnectKick from './components/ConnectKick';
// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app); // Initialize Firestore

// PrivateRoute component
const PrivateRoute = ({ element, user }) => {
  return user ? element : <Navigate to="/" />; // Redirect to home if not logged in
};

function App() {
  const [user, setUser] = useState(null);
  const [userEmotes, setUserEmotes] = useState([]); // Add this line

  // Add this new function to fetch updated user data
  const refreshUserData = async () => {
    if (user) {
      const userDoc = doc(db, 'users', user.uid);
      const userSnapshot = await getDoc(userDoc);
      if (userSnapshot.exists()) {
        setUser({ ...user, ...userSnapshot.data() });
      }
    }
  };

  useEffect(() => {
    // Automatically sign in the user if they are already authenticated
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        // Check if user exists in Firestore
        const userDoc = doc(db, 'users', currentUser.uid);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUser({ ...currentUser, ...userData });
          setUserEmotes(userData.emotes || []); // Add this line
        }
      } else {
        setUser(null);
        setUserEmotes([]); // Clear emotes when user logs out
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []); // Empty dependency array to run once on mount

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log(user);

      // Check if user exists in Firestore
      const userDoc = doc(db, 'users', user.uid);
      const userSnapshot = await getDoc(userDoc);

      let userData;
      if (!userSnapshot.exists()) {
        // Save user profile if it doesn't exist
        userData = {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          createdAt: new Date(),
          credits: 0,
        };
        await setDoc(userDoc, userData);
        console.log('User profile created in Firestore');
      } else {
        userData = userSnapshot.data();
        console.log('User profile fetched from Firestore');
      }

      // Set user state with Firestore data
      setUser({ ...user, ...userData });
    } catch (error) {
      console.error("Error during sign-in popup:", error);
    }
  };

  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          const user = result.user;
          console.log(user);
          setUser(user);

          // Check if user exists in Firestore
          const userDoc = doc(db, 'users', user.uid);
          const userSnapshot = await getDoc(userDoc);

          if (!userSnapshot.exists()) {
            // Save user profile if it doesn't exist
            await setDoc(userDoc, {
              displayName: user.displayName,
              email: user.email,
              photoURL: user.photoURL,
              createdAt: new Date(),
              credits: 0,
            });
            console.log('User profile created in Firestore');
          } else {
            console.log('User profile already exists in Firestore');
          }
        }
      } catch (error) {
        console.error("Error handling redirect result:", error);
      }
    };

    handleRedirectResult();
  }, []);

  const signOutGoogle = async () => {
    try {
      await auth.signOut();
      setUser(null);
      console.log('User signed out successfully');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const saveKickUsername = async (userDoc) => {
    const kickUsername = prompt("Please enter your Kick username:"); // Prompt user for their Kick username
    if (kickUsername) {
      await setDoc(userDoc, { kickUsername }, { merge: true }); // Save username to Firestore
      console.log('Kick username saved to Firestore');
    }
  };

  console.log(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Emote Ninja</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Toaster position="top-right" toastOptions={{ duration: 2000, className: "bg-zinc-600 text-white" }} />
      <Navbar clickSignOut={signOutGoogle} user={user} clickSignIn={signInWithGoogle} />
      <Routes>
        <Route path="/" element={<Home user={user} />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/how-to-connect-kick" element={<ConnectKick />} />
        <Route path="/getstarted" element={<GetStarted user={user} />} />
        <Route path="/profile" element={<PrivateRoute element={<Profile saveKickUsername={saveKickUsername} />} user={user} />} />
        <Route path="/generator" element={
          <PrivateRoute
            element={
              <div style={{ marginTop: '100px', textAlign: 'center', fontSize: '2rem', color: 'gray', width: "100%" }}>
                <Generator userEmotes={userEmotes} refreshUserData={refreshUserData} />
              </div>
            }
            user={user}
          />
        } />
        <Route path="/community" element={<Community />} />
        <Route path="/image-editor" element={<ImageEditor emotes={userEmotes} user={user} />} />
        <Route path="/test" element={<div style={{ marginTop: '100px' }}><Test /></div>} />
        <Route path="*" element={<div style={{ paddingTop: '100px', textAlign: 'center', fontSize: '2rem', color: 'gray', width: "100%" }}>That page doesn't exist!</div>} />
      </Routes>
    </div>
  );
}

export default App;
