import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { fetchUsers } from '../database'; // Assume this function now returns users with their saved emotes
import { motion } from 'framer-motion';

export default function Home({ user }) {
  const [users, setUsers] = useState([]);
  const recentCreations = useRef(null)

  useEffect(() => {
    const loadUsers = async () => {
      // Fetch up to 20 users from the database, including their saved emotes
      const fetchedUsers = await fetchUsers(20);
      let emoteChain = [];
      for (let i = 0; i < fetchedUsers.length; i++) {
        for (let j = 0; j < fetchedUsers[i].emotes.length; j++) {
          let user = {
            username: fetchedUsers[i].name,
            imageUrl: fetchedUsers[i].emotes[j].imageUrl
          }
          emoteChain.push(user);
        }
      }
      const emotes = await Promise.all(emoteChain);
      setUsers(emotes);
    };

    loadUsers();
  }, []);

  const drawImageToCanvas = (imageUrl, username, canvasElement) => {
    const ctx = canvasElement.getContext('2d');
    const img = new Image();
    img.crossOrigin = "Anonymous";  // This allows loading images from other domains
    img.onload = () => {
      // Set canvas size (adjust as needed)
      canvasElement.width = 200;
      canvasElement.height = 200;

      // Draw image at lower resolution
      ctx.drawImage(img, 0, 0, canvasElement.width, canvasElement.height);

      // Add username overlay
      ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
      ctx.fillRect(0, 0, canvasElement.width, canvasElement.height);
      ctx.fillStyle = 'white';
      ctx.font = '20px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(username, canvasElement.width / 2, canvasElement.height / 2);
    };
    img.src = imageUrl;
  };

  return (
    <div className='mx-auto mt-20 min-h-screen gap-40 grid justify-center align-center align-items-center'>
      <div className="p-10"></div>
      <h6 className="typewriter text-2xl">
        EmoteNinja
      </h6>
      <div className="w-full grid place-items-center justify-center align-center p-5">
        <div className=" px-4 sm:px-6 lg:px-8 flex justify-center items-center">
          <div className="flex flex-col items-center justify-center space-y-8">
            <div className="flex flex-col items-center justify-center gap-7">
              {/* <img src={require("../assets/Logo.png")} alt="Emote Ninja Logo" className="mx-auto mb-4 w-32 h-32 object-contain" /> */}
              <p className="text-white text-[16px] monospace max-w-[700px]">
                Elevate your stream with unique, AI-generated emotes. Stand out on Kick.com with custom emotes created just for you!
              </p>

              {user && (
                <div className="flex justify-center items-center gap-4">
                  <Link to="/generator">
                    <button className="text-white border-2 border-white bg-transparent py-3.5 px-10 rounded-[55px] flex items-center space-x-2 hover:bg-white hover:text-black transition duration-300 group">
                      <span className="group-hover:text-black transition-colors duration-300">Start Generating</span>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M8 10.5l4.5 4.5 3.5-3.5M20.25 5.507c.83.07 1.5.742 1.5 1.593 0 .848-.67 1.52-1.5 1.593l-1.254 4.183A1.75 1.75 0 0117.25 14h-10.5a1.75 1.75 0 01-1.746-1.124L3.75 8.693C2.92 8.62 2.25 7.948 2.25 7.1c0-.851.67-1.523 1.5-1.593L6 2.252V2.25a.75.75 0 01.75-.75h10.5a.75.75 0 01.75.75v.002l2.25 3.255zm-2.43 3.12l-2.25-3.255V2.25h-7.5v3.122l-2.25 3.255 1.614 5.373h8.772l1.614-5.373z" />
                      </svg>
                    </button>
                  </Link>

                  <button onClick={() => recentCreations.current.scrollIntoView({ behavior: 'smooth' })} className="text-black border-2 border-white bg-white py-3.5 px-10 rounded-[55px] flex items-center space-x-2 hover:bg-white hover:text-black transition duration-300 group">
                    <span className="group-hover:text-black transition-colors duration-300">Recent Creations</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
              )}

              {!user && (
                <button className="text-white text-xl font-['Press Start 2P'] bg-black px-4 py-2 rounded-xl mt-10">Get Started</button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Add this new section for the emote grid */}
      <div ref={recentCreations}></div>
      <div className="mt-20">
        <h1 className="text-3xl font-bold mb-8 text-center text-white">Recently Created</h1>
        <div className='container mx-auto px-4'>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-6">
            {users.map((emote, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="relative group"
              >
                <canvas
                  ref={(el) => {
                    if (el) {
                      drawImageToCanvas(emote.imageUrl, emote.username, el);
                    }
                  }}
                  className="rounded-lg w-full h-auto shadow-lg transition-shadow duration-300 group-hover:shadow-xl"
                ></canvas>
              </motion.div>
            ))}
          </div>
          <div className="p-10"></div>
        </div>
      </div>
    </div>
  );
}
