import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getFirestore, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { removeBackground } from '../server'; // Assuming this function is exported from server.js
import toast from 'react-hot-toast';

const Modal = ({ images, initialImageUrl, prompt, onClose, refreshUserData }) => {
  const [imageUrl, setImageUrl] = useState(initialImageUrl);
  const [isRemoving, setIsRemoving] = useState(false);

  const handleDownload = () => {
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `emote-${prompt.replace(/\s+/g, '-').toLowerCase()}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error downloading image:', error);
        toast.error('Failed to download image. Please try again.');
      });
  };

  const handleRemoveBackground = async () => {
    setIsRemoving(true);
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('User not authenticated');
      setIsRemoving(false);
      return;
    }

    const db = getFirestore();
    const userDoc = doc(db, 'users', user.uid);

    try {
      const newImageUrl = await removeBackground(imageUrl);

      const userSnapshot = await getDoc(userDoc);
      const userData = userSnapshot.data();
      const currentCredits = userData.credits;

      if (!newImageUrl) {
        throw new Error('No image URL returned from removeBackground');
      }

      if (newImageUrl === "error") {
        toast.error('An error occurred while removing the background. Please try again.');
        throw new Error("Server returned an error");
      }

      // Update image URL
      setImageUrl(newImageUrl);
      toast.success('Background removed successfully');

      // Deduct credits
      await updateDoc(userDoc, { credits: currentCredits - 25 });
      // Update user document with new emotes
      await updateDoc(userDoc, {
        emotes: arrayUnion({
          prompt: prompt,
          imageUrl: newImageUrl,
          createdAt: new Date(),
          primaryColor: "#000000"
        })
      });
      toast.success('Background removed successfully, 25 credits deducted');
    } catch (error) {
      console.error('Error in handleRemoveBackground:', error);
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsRemoving(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-zinc-800 rounded-lg p-6 overflow-y-auto max-w-[500px]">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-white">{prompt}</h3>
          <button
            onClick={onClose}
            className="text-gray-300 hover:text-white hover:scale-110 transition-all duration-300 hover:bg-zinc-700 p-1 rounded-md"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <img src={imageUrl} alt={prompt} className="flex justify-center items-center align-center w-full h-auto mb-4 max-w-[500px] max-h-[500px]" />

        <div className="flex flex-row w-full overflow-x-auto mb-4 gap-2">
          {images.map((image, index) => (
            <img
              key={index}
              src={image.imageUrl}
              alt={`Generated emote ${index + 1}`}
              className="w-16 h-16 object-cover rounded-md cursor-pointer hover:opacity-80 transition-opacity"
              onClick={() => setImageUrl(image.imageUrl)}
            />
          ))}
        </div>

        <div className="flex justify-center">
          <button
            onClick={handleDownload}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 mr-2 text-sm"
          >
            Download
          </button>
          <button
            onClick={handleRemoveBackground}
            disabled={isRemoving}
            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 text-sm"
          >
            {isRemoving ? 'Removing...' : 'Remove Background (25 Credits)'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;