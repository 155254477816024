import React, { useState, useEffect, useCallback } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc, arrayUnion, arrayRemove, deleteField } from 'firebase/firestore';
import 'react-image-crop/dist/ReactCrop.css';
import { removeBackground } from '../server';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { uploadImage } from '../server';
import { Fragment } from 'react';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';

export default function Profile() {
  const [user, setUser] = useState(null);
  const [kickUsername, setKickUsername] = useState('');
  const [emotes, setEmotes] = useState([]);
  const [selectedEmote, setSelectedEmote] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [index, setIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('emotes');
  const [generatedCode, setGeneratedCode] = useState('');

  const fetchUserEmotes = async (userId) => {
    const db = getFirestore();
    const userDoc = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userDoc);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      if (userData.emotes && Array.isArray(userData.emotes)) {
        return userData.emotes;
      } else {
        console.log('No emotes found for this user');
        return [];
      }
    } else {
      console.log('User document not found');
      return [];
    }
  };



  const deleteEmote = async (emote) => {
    console.log(emote)
    const auth = getAuth();
    const db = getFirestore();
    const userDoc = doc(db, 'users', auth.currentUser.uid);
    console.log(user.uid)
    await updateDoc(userDoc, {
      emotes: arrayRemove(emote)
    }).then(() => {
      toast.success('Emote deleted successfully');
      setEmotes(prevEmotes => prevEmotes.filter(e => e.imageUrl !== emote.imageUrl));
      setIsModalOpen(false)
    }).catch((error) => {
      console.error('Error deleting emote:', error);
      toast.error('Failed to delete emote');
    });
  };

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userDoc = doc(db, 'users', auth.currentUser.uid);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          setUser(userSnapshot.data());
          setKickUsername(userSnapshot.data().kickUsername || '');

          // Fetch user's emotes
          const userEmotes = await fetchUserEmotes(auth.currentUser.uid);
          setEmotes(userEmotes);
        }
      }
    };

    fetchUserData();
  }, []);

  const handleRemoveBackground = async (emote) => {
    setIsProcessing(true);
    const auth = getAuth();
    const user = auth.currentUser;

    const db = getFirestore();
    const userDoc = doc(db, 'users', user.uid);
    const userSnapshot = await getDoc(userDoc);
    const userData = userSnapshot.data();
    const currentCredits = userData.credits || 0;

    try {
      const base64Image = await removeBackground(emote.imageUrl);

      // Upload the image and get URL
      const response = await fetch(base64Image);
      const blob = await response.blob();
      const file = new File([blob], `emote_${Date.now()}.png`, { type: 'image/png' });
      const uploadedImageUrl = await uploadImage(file);

      setSelectedEmote({ imageUrl: uploadedImageUrl });
      await updateDoc(userDoc, { credits: currentCredits - 25 });
      await updateDoc(userDoc, {
        emotes: arrayUnion({
          prompt: "",
          imageUrl: base64Image,
          createdAt: new Date(),
        })
      });
    } catch (error) {
      console.error("Error removing background:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const openModal = (emote, i) => {
    setSelectedEmote(emote);
    setIndex(i);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEmote(null);
  };

  const handleDownload = useCallback(() => {
    if (selectedEmote && selectedEmote.imageUrl) {
      fetch(selectedEmote.imageUrl)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // Ensure the file extension is .png
          a.download = `${selectedEmote.name || 'emote'}.png`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch(error => console.error('Error downloading image:', error));
    }
  }, [selectedEmote]);

  const handleCopy = () => {
    // Implement copy logic
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleGenerateCode = async () => {
    const auth = getAuth();
    const db = getFirestore();

    if (auth.currentUser) {
      const userDoc = doc(db, 'users', auth.currentUser.uid);
      const randomCode = uuidv4();
      await updateDoc(userDoc, { randomCode });
      setGeneratedCode(randomCode); // Update the state with the generated code
      toast.success('Code generated and saved successfully');
    } else {
      toast.error('User not authenticated');
    }
  };

  if (!user) {
    return <div>Loading...</div>
  }

  return (
    <div className="w-full py-12 px-4 sm:px-6 lg:px-8 mt-20">
      <div className="flex flex-col items-center w-full gap-4 rounded-[3rem] p-4 sm:p-6 mb-20">
        <div className="flex flex-col items-center gap-2 sm:flex-row sm:gap-4">
          <img className="object-cover rounded-full h-14 w-14" src={"https://pbs.twimg.com/profile_images/1796515977598742529/oZDQ4vAB_400x400.jpg"} alt={user.displayName} />
          <h2 className="text-xl sm:text-2xl leading-tight font-bold text-white font-['Inter', sans-serif] text-center sm:text-left">@{user.displayName}</h2>
        </div>
        <Link to="/generator" className="w-full sm:w-auto mt-10">
          <button className="bg-zinc-900 text-white rounded-md px-4 py-2 w-full sm:w-auto">Create An Emote</button>
        </Link>
      </div>

      {/* Tab selection */}
      <div className="flex border-b border-gray-700 mt-8">
        <button
          className={`py-2 px-4 ${activeTab === 'emotes' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
          onClick={() => handleTabChange('emotes')}
        >
          Emotes
        </button>
        <button
          className={`py-2 px-4 ${activeTab === 'profile' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
          onClick={() => handleTabChange('profile')}
        >
          Edit Profile
        </button>
      </div>

      {/* Emotes Tab Content */}
      {activeTab === 'emotes' && (
        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-2 p-5 mt-5">
          {emotes.map((emote, i) => (
            <div
              key={emote.id}
              className="grid p-2 hover:bg-zinc-800 rounded-lg cursor-pointer"
              onClick={() => openModal(emote, i)}
            >
              <img className="rounded-lg w-full h-auto" src={emote.imageUrl} alt={emote.name} />
            </div>
          ))}
        </div>
      )}

      {/* Edit Profile Tab Content */}
      {activeTab === 'profile' && (
        <div className="mt-5 p-5">
          <div className="space-y-4 grid gap-5">
            <div className="bg-zinc-800 rounded-lg p-5 gap-2 grid">
              <label className="block text-left text-lg font-medium text-gray-300">Kick Connect</label>
              <div className="text-sm text-gray-200 text-left">In order to receive credits for subscribing to the DevGwardo channel, you will have to link your account here.</div>

              <div className="block text-left text-lg font-medium text-gray-300">
                Connected Kick Username
              </div>

              <div className="text-gray-400 text-left bg-zinc-900 rounded-lg p-2">
                {kickUsername || "Not Connected"}
              </div>

              <div className="block text-left text-lg font-medium text-gray-300">
                Reconnect Kick
              </div>

              <div className="text-sm text-left text-gray-200">
                To reconnect your Kick account click generate button below which will provide you with a command that you should execute in the Kick chat
              </div>

              <button className="bg-white text-black rounded-md px-4 py-2" onClick={handleGenerateCode}>
                Generate Code
              </button>

              {generatedCode && (
                <div
                  className="mt-2 text-white bg-zinc-900 p-2 rounded cursor-pointer font-sans"
                  onClick={() => {
                    navigator.clipboard.writeText(`!connect-kick ${generatedCode}`);
                    toast.success('Code copied to clipboard');
                  }}
                >
                  !connect-kick {generatedCode}
                </div>
              )}
            </div>
          </div>

          <div className="bg-zinc-800 rounded-lg p-5 gap-2 grid mt-10">
            <label className="block text-left text-lg font-medium text-gray-300">Learn How To Connect Kick</label>
            <div className="text-sm text-gray-200 text-left">
              Step-by-Step Connection: Learn how to connect you Kick.com account to earn points.
            </div>

            <Link to="/how-to-connect-kick" >
              <button className="bg-white text-black rounded-md px-4 py-2 w-full">
                Learn How
              </button>
            </Link>
          </div>
        </div>
      )}

      {isModalOpen && selectedEmote && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-zinc-900 rounded-lg max-w-3xl w-full max-h-[90vh] overflow-auto">
            <div className="p-4 flex justify-between items-center">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-zinc-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-700">
                    Options
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-left rounded-md bg-zinc-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handleDownload}
                            className={`${active ? 'bg-zinc-700 text-white' : 'text-gray-300'
                              } block px-4 py-2 text-sm w-full text-left`}
                          >
                            Download
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handleCopy}
                            className={`${active ? 'bg-zinc-700 text-white' : 'text-gray-300'
                              } block px-4 py-2 text-sm w-full text-left`}
                          >
                            Copy
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => handleRemoveBackground(selectedEmote)}
                            className={`${active ? 'bg-zinc-700 text-white' : 'text-gray-300'
                              } block px-4 py-2 text-sm w-full text-left`}
                          >
                            <div className="flex flex-row">Remove Background</div>
                            <div className="text-gray-300 text-xs">(-25 Credits)</div>
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => deleteEmote(selectedEmote)}
                            className={`${active ? 'bg-zinc-700 text-white' : 'text-gray-300'
                              } block px-4 py-2 text-sm w-full text-left`}
                          >
                            Delete
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <button
                onClick={closeModal}
                className="text-white hover:text-gray-300"
              >
                Close
              </button>
            </div>
            <img
              src={selectedEmote.imageUrl}
              alt={selectedEmote.name}
              className="w-full h-auto"
            />
            {isProcessing ? (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <svg className="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}
